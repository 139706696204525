import request from '../utils/request';

//获取等离子体的历史数据
export const getDegnlizihis = data => {
  return request({
    url: '/kanban/api/export',
    method: 'post',
    data,
    headers: {
      'CustomRequestType': 'application/x-www-form-urlencoded',
      // 'Access-Control-Allow-Origin': '*'
    }
  });
};
//上海芯牙
export const plasmaIonData = data => {
  return request({
    url: '/kanban/api/plasmaIonData',
    method: 'post',
    data,
    headers: {
      'CustomRequestType': 'application/x-www-form-urlencoded',
      // 'Access-Control-Allow-Origin': '*'
    }
  });
};
//获取水离子的历史数据
export const getshuilizihis = data => {
  return request({
    url: '/kanban/api/waterIonData',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//修改机器人名称
export const changeRobotName = data => {
  return request({
    url: '/kanban/system/updataName',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 获取列表
export const deviceList = query => {
  return request({
    url: '/kanban/api/deviceList',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 修改设备名称
export const modifyDeviceName = query => {
  return request({
    url: '/kanban/api/modifyDeviceName',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 设备开关机
export const switchDevice = query => {
  return request({
    url: '/kanban/api/switchDevice',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
// 获取设备当前状态
export const getDeviceState = query => {
  return request({
    url: '/kanban/api/getDeviceState',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//获取list的全部数据
export const getDeviceData = query => {
  return request({
    url: '/kanban/api/getDeviceData',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//新增定时器
export const chooseInterval = query => {
  return request({
    url: '/api/homeDevice/addTimerSwitch',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//启用定时器
export const startInterval = query => {
  return request({
    url: '/api/homeDevice/enableSwitch',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//获取定时的list
export const getTimerTask = query => {
  return request({
    url: '/api/homeDevice/getTimerTask',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//删除定时器
export const delTimerTask = query => {
  return request({
    url: '/api/homeDevice/deleteTimerSwitch',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//停用定时器
export const stopTimerTask = query => {
  return request({
    url: '/api/homeDevice/DisableSwitch',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//获取定时器所有列表
export const getTimerTaskList = query => {
  return request({
    url: 'api/homeDevice/getAllTimerTask',
    method: 'get',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//修改定时器
export const changeTimerTask = query => {
  return request({
    url: 'api/homeDevice/modifyTimerSwitch',
    method: 'post',
    data: query,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};
//获取天青等离子表格数据
export const plasmalonData = data => {
  return request({
    url: '/kanban/TQ/plasmaIonData',
    method: 'post',
    data
  });
};
//向平台发送开关机指令
export const getDeviceStatus = data => {
  return request({
    url: '/api/home4GDevice/getDeviceStatus',
    method: 'post',
    data
  });
};

